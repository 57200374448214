import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import BlockStyles from 'components/BlockStyles';
import { DATA_SOURCE_FUTURES, DATA_SOURCE_INDEX } from 'utils/constants';
import { findContractExpirationDate } from 'utils/functions';
import MarketDataContext from '../../../context/MarketDataContext';
import MonthContext from '../../../context/MonthContext';
import SiteSettingsContext from '../../../context/SiteSettingsContext';
import { MONTH_INDICES } from '../../../utils/constants';
import dayjs from '../../../utils/dayjs';
import WidgetTitle from '../SharedComponents/WidgetTitle';
import CandlestickGraph from './CandlestickGraph';
import DataColumns from './DataColumns';
import PriceHeading from './PriceHeading';
import IndexContext from '../../../context/IndexContext';

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    flex-direction: column;
    align-items: center;
  }
`;

const GraphContainer = styled.div`
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 0.5rem;
  width: 100%;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    flex: 1 1 70%;
    max-width: 65%;
  }
`;

const propTypes = {
  fields: PropTypes.array,
  symbol: PropTypes.string,
  template: PropTypes.string,
  title: PropTypes.object,
  tooltip: PropTypes.object
};

const defaultProps = {
  fields: [],
  symbol: '',
  template: '',
  title: {},
  tooltip: {}
};

function PriceMovement({
  fields,
  symbol,
  title,
  template,
  tooltip,
  productName
}) {
  const widgetTitle = title.text && title.text;
  const { selectedMonth } = useContext(MonthContext);
  const indices = useContext(IndexContext);
  const { summaryTradeData: data } = useContext(MarketDataContext);
  const siteSettings = useContext(SiteSettingsContext);

  const dataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].price_movement_widget_data_source) ||
    DATA_SOURCE_INDEX;
  const activeSymbol =
    dataSource === DATA_SOURCE_FUTURES ? selectedMonth.symbol : indices[symbol];

  const summary =
    (data && data[activeSymbol] && data[activeSymbol].Summary) || {};
  const trade = (data && data[activeSymbol] && data[activeSymbol].Trade) || {};

  if (!summary.eventSymbol) {
    Object.assign(summary, {
      eventSymbol: activeSymbol
    });
  }

  // This appends the summary object with a new key/value pair for contractExp,
  // which allows the logic for determining which override value to display
  // from prismic (if one exists)
  if (selectedMonth && summary && summary.eventSymbol === activeSymbol) {
    Object.assign(summary, {
      contractExp: selectedMonth.contractExp,
      expiration: findContractExpirationDate(
        dayjs()
          .tz()
          .month(MONTH_INDICES[selectedMonth.month]),
        activeSymbol
      ).format('MM/DD/YYYY')
    });
  }

  return (
    <BlockStyles template={template}>
      <div className='block'>
        <WidgetTitle color='black' title={widgetTitle} tooltip={tooltip} />
        <Content>
          <GraphContainer>
            <PriceHeading
              productName={productName}
              summary={summary}
              trade={trade}
              symbol={symbol}
            />
            <CandlestickGraph symbol={symbol} />
          </GraphContainer>
          <DataColumns
            dataSource={dataSource}
            fields={fields}
            summary={summary}
            tradeData={trade}
          />
        </Content>
      </div>
    </BlockStyles>
  );
}

PriceMovement.propTypes = propTypes;
PriceMovement.defaultProps = defaultProps;

export default PriceMovement;
